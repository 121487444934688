import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SpecialDateService} from '../../../services/special-date.service';
import {DatePipe} from '@angular/common';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-add-special-date',
  templateUrl: './add-special-date.component.html',
  providers: [SpecialDateService]
})
export class AddSpecialDateComponent implements OnInit {
  minDate: any;
  date = '';
  reasonValue = '';
  @Output() dateAdded = new EventEmitter<boolean>();


  constructor(private specialDateService: SpecialDateService,
              public datepipe: DatePipe,
              private toastService: ToastrService,
              public activeModal: NgbActiveModal) {
    this.minDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {
  }

  handleFormSubmit(dateForm: any) {
    if (dateForm.invalid) {
      dateForm.markAllAsTouched();
      return;
    }
    this.specialDateService.createSpecialDate({
      date: this.date,
      active: true,
      reason: this.reasonValue
    }).subscribe(() => {
        this.dateAdded.emit(true);
        this.activeModal.dismiss(true);
        this.toastService.success('Holiday created', 'Success');

      }, () => {
        this.dateAdded.emit(false);
        this.toastService.error('Holiday not created', 'Error');

      }
    );
  }

}
